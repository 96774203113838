import Choices from 'choices.js';
import SimpleBar from 'simplebar';
window.initChoices = function initChoices() {
const choicesArr = document.querySelectorAll(".js-choices");

if (choicesArr.length) {
    choicesArr.forEach(select => {
        new Choices(select, {
            allowHTML: true,
            searchEnabled: false,
            placeholder: true,
            placeholderValue: null,
            searchPlaceholderValue: null,
            shouldSort: false,
        });
    });

    const choicesWrappers = document.querySelectorAll(".choices");
    choicesWrappers.forEach(choiceWrap => {
        choiceWrap.addEventListener("click", () => {
            if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
            const selectSimplebar = choiceWrap.querySelector(
                ".choices__list--dropdown .choices__list"
            );
            const div = document.createElement("div");
            div.classList.add("custom-simplebar-wrapper");
            const content = selectSimplebar.innerHTML;
            selectSimplebar.innerHTML = "";
            new SimpleBar(div)
            div.innerHTML = content;
            selectSimplebar.appendChild(div);
            }
        });
    });
}
}

initChoices()