import Swiper from 'swiper';
import { Mousewheel, Scrollbar, FreeMode, Navigation, Autoplay, Thumbs, EffectFade } from 'swiper/modules';
import { swiperOnResize } from '../utils/swiperOnResize.utils';

const autoPlayOption = {
    speed: 500,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
}

if (document.querySelector('.testimonials__slider')) {
    const mask = document.querySelector('.testimonials__mask');

    const thumbSwiper = new Swiper(".testimonials__thumbs", {
        modules: [FreeMode, Navigation, Thumbs, Scrollbar, Mousewheel ],
        slidesPerView: 1,
        watchSlidesProgress: true,
        spaceBetween: 15,
        mousewheel: true,
        scrollbar: {
            el: ".testimonials__scrollbar",
            draggable: true,
        },
        breakpoints: {
            650: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1025: {
                direction: "vertical",
                slidesPerView: 4,
                spaceBetween: 0,
            },
        },


    });

    const mainSlider = new Swiper(".testimonials__slider", {
        modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        navigation: {
            prevEl: '.testimonials .slider-btn.prev',
            nextEl: '.testimonials .slider-btn.next',
        },
        thumbs: {
            swiper: thumbSwiper,
        },
        on: {
            slideChange: (e) => {
                if (mask.classList.contains('is-hide') && e.activeIndex !== e.slides.length - 1) {
                    mask.classList.remove('is-hide')
                }

                if (e.activeIndex === e.slides.length - 1) {
                    mask.classList.add('is-hide')
                }

            }
        },
        speed: 500,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
    });
}

if (document.querySelector('.pick__slider')) {
    const thumbSwiper = new Swiper(".pick__thumbs", {
        modules: [FreeMode, Navigation, Thumbs, Scrollbar, Mousewheel, Autoplay],
        ...autoPlayOption,
        slidesPerView: 1,
        watchSlidesProgress: true,
        spaceBetween: 15,
        mousewheel: true,
        scrollbar: {
            el: ".pick__scrollbar",
            draggable: true,
        },
        breakpoints: {
            470: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            650: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1025: {
                slidesPerView: 4,
                spaceBetween: 20,
                mousewheel: false,
            },
        },



    });

    const mainSlider = new Swiper(".pick__slider", {
        modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        thumbs: {
            swiper: thumbSwiper,
        },
        autoHeight: true,
        allowTouchMove: false,
        noSwiping: true,
    });
}


if (document.querySelector(".jobs__slider")) {
    new Swiper(".jobs__slider", {
        modules: [Autoplay, Navigation],
        ...autoPlayOption,
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
            },
        },
        navigation: {
            prevEl: '.jobs .slider-btn.prev',
            nextEl: '.jobs .slider-btn.next',
        }
    });
}
if (document.querySelector(".candidates__result")) {
	swiperOnResize('(max-width: 1024px)', '.candidates__result', {
      modules: [Autoplay, Navigation],
      ...autoPlayOption,
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
            },
      },
      navigation: {
          prevEl: '.candidates__nav-btn.prev',
          nextEl: '.candidates__nav-btn.next',
      }
  });
}


if (document.querySelector(".wedo__slider")) {
    new Swiper(".wedo__slider", {
        modules: [Autoplay, Navigation],
        ...autoPlayOption,
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
            },
        },
        navigation: {
            prevEl: '.wedo .slider-btn.prev',
            nextEl: '.wedo .slider-btn.next',
        }
    });
}

