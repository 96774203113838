import SimpleBar from "simplebar";

const teamCards = () => {
    const cards = document.querySelectorAll('.team__card');
    
    cards.forEach(card => {
        if (!card.classList.contains('team__card--btn')) {
       
            if (card.querySelector('.team__simplebar')) {
                const scrollableContainer = new SimpleBar(card.querySelector('.team__simplebar'));

                scrollableContainer.getScrollElement().addEventListener('scroll', function () {
                    let isAtBottom = (this.scrollTop + this.clientHeight + 30 >= this.scrollHeight);
              
                    if (isAtBottom) {
                        card.classList.add('is-bottom');
                    } else {
                        card.classList.remove('is-bottom');
                    }
                });
            } else {
                card.classList.add('no-text');
            }

            card.addEventListener('click', (e) => {
                if (!e.target.classList.contains('team__contact')) {
                    card.classList.add('is-active');
                }
            })
    
            document.addEventListener('click', (e) => {
                const click = e.composedPath().includes(card);
                if (!click) {
                    card.classList.remove('is-active');
                }
            })
        }
    })
}


teamCards()