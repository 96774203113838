const buttons = document.querySelectorAll('.btn');

buttons.forEach((button) => {
  const span = document.createElement('SPAN');
  span.classList.add('btn__text-wrap');
  span.innerHTML = button.innerHTML;
  button.textContent = '';
  button.appendChild(span);
  const buttonComputedStyles = getComputedStyle(button);

  button.addEventListener('mousemove', (e) => {
    const rect = button.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    // Розрахунок зміни позиції для span елементу
    const translateX = (offsetX - rect.width / 2) / 6;
    const translateY = (offsetY - rect.height / 2) / 6;
    const maxTranslateX =
      Math.min(
        parseFloat(buttonComputedStyles.paddingLeft),
        parseFloat(buttonComputedStyles.paddingRight)
      ) - 5;
    const maxTranslateY =
      Math.min(
        parseFloat(buttonComputedStyles.paddingTop),
        parseFloat(buttonComputedStyles.paddingBottom)
      ) - 5;

    const resultTranslateX = Math.min(translateX, maxTranslateX);
    const resultTranslateY = Math.min(translateY, maxTranslateY);

    span.style.transform = `translate(${resultTranslateX}px, ${resultTranslateY}px)`;
  });
  button.addEventListener('mouseleave', () => {
    span.style.transform = null;
  });
});
